<template>
  <div>

    <div class="vertical-center">

      <div class="container-fluid col-xs-10 col-sm-10 col-md-12 col-lg-12 col-offset-1">

        <!-- Login Copy -->
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <h3>Account Recovery</h3>
            <p>Enter your email address to be sent password recovery instructions.</p>
          </div>
        </div>

        <!-- Login Form -->
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <form v-on:submit.prevent="onSubmit">
              <div class="form-group">
                <label for="username_login">Email:</label>
                <input
                  id="username_login"
                  class="form-control"
                  type="email"
                  v-model="email"
                  placeholder="Email..."
                  name="email"
                  required
                />
              </div>

              <input type="submit" class="btn btn-primary" value="Send Email"/>

              <br>
              <br>

              <p><router-link class="link" :to="{ name: 'Login' }">Log In</router-link></p>
            </form>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import AuthService from '@/common/services/auth.service';

export default {
  name: 'AccountRecovery',
  data() {
    return {
      email: null,
      error: null,
    };
  },
  methods: {
    onSubmit() {
      AuthService.recover(this.email)
        .catch((err) => {
          if (err.response && err.response.data) {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.error,
              width: 350,
            });
          }
        });
    },
  },
};

</script>

<style lang="scss" scoped>

  @import "../../styles/views/authentication";

</style>
